import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  list: [],
  listTimesheet: [],
  timesheetPeriod: [],
  error: '',
  customers: [],
  totalTimeSheetByMonthData: [],
  jobs: [],
}

const mutations = make.mutations(state)

const getters = make.getters(state)

const actions = {
  ...make.actions(state),
  getTotalTimeSheetByMonth: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    let fullUrl = api.baseUrl + '/timesheet/export'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('totalTimeSheetByMonthData', response.data.data.timesheets)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getTimeSheets: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('error', '')

    let fullUrl = api.baseUrl + '/timesheet'
    const jobIdList = payload.jobId
    delete payload.jobId
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    if (jobIdList) {
      if (jobIdList.length > 0) {
        const list = jobIdList.map(el => `&jobId=${el}`)
        fullUrl += list.join('')
      } else {
        fullUrl += '&jobId=' + jobIdList
      }
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('list', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          if (error.response.status === 400) {
            commit('error', 'error.none_id')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getCustomers: ({ commit }) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/customer'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('customers', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getJobs: ({ commit }, id) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/customer/' + id + '/job'

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('jobs', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getAttendanceTimeSheets: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('error', '')

    let fullUrl = api.baseUrl + '/timesheet/period'
    const isSubmitted = payload.isSubmitted
    const customerId = payload.customerId
    delete payload.isSubmitted
    delete payload.customerId
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    if (isSubmitted) {
      if (isSubmitted === 1) {
        fullUrl += '&isSubmitted=' + true
      } else {
        fullUrl += '&isSubmitted=' + false
      }
    }
    if (customerId) {
      fullUrl += '&customerId=' + customerId
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('listTimesheet', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          if (error.response.status === 400) {
            commit('error', 'error.none_id')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  submitTimesheet: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.timesheetId
    delete payload.timesheetId
    Vue.axios
      .post(api.baseUrl + '/timesheet/period/' + id + '/submit', payload)
      .then(response => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  getTimesheetPeriod: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('error', '')
    const id = payload.id
    delete payload.id
    const fullUrl = api.baseUrl + '/timesheet/period/' + id
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('timesheetPeriod', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          if (error.response.status === 400) {
            commit('error', 'error.none_id')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  createTimesheet: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    Vue.axios
        .post(api.baseUrl + '/timesheet/period', payload)
        .then(response => {
          commit('status', '')
          commit('message', 'success')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  createTimesheetFromShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.id
    delete payload.id
    Vue.axios
        .put(api.baseUrl + '/timesheet/period/' + id, payload)
        .then(response => {
          commit('status', '')
          commit('message', 'createSuccess')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  updateTimesheet: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let id = ''
    if (payload.timesheetId) {
      id = payload.timesheetId
    }
    delete payload.timesheetId
    Vue.axios
        .post(api.baseUrl + '/timesheet/period/' + id, payload)
        .then(response => {
          commit('status', '')
          commit('message', 'updateSuccess')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  deletePeriod: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    Vue.axios
        .delete(api.baseUrl + '/timesheet/period/' + payload)
        .then(response => {
          commit('status', '')
          commit('message', 'deleteSuccess')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
