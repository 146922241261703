// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { layout, route } from '@/util/routes'
import store from '@/store'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
      route('Dashboard', null, '', true),
      route(
        'ListEmployee',
        { default: 'Employee/index' },
        '/employee-list',
        true,
        [1],
      ),
      route(
        'EmployeeEdit',
        { default: 'Employee/Edit' },
        '/employee-edit/:id',
        true,
        [1],
      ),
      route('EmployeeAdd', { default: 'Employee/Add' }, '/employee', true, [1]),
      route(
        'ParttimerList',
        { default: 'Parttimer/index' },
        '/parttimer-list',
        true,
        [1, 2],
      ),
      route(
        'ParttimerListImport',
        { default: 'Parttimer/ImportCSV' },
        '/parttimer-list/import',
        true,
        [1, 2],
      ),
      route(
        'ParttimerListDetail',
        { default: 'Parttimer/Detail' },
        '/parttimer-detail/:id',
        true,
        [1, 2],
      ),
      route(
        'ParttimerHistory',
        { default: 'Parttimer/History' },
        '/parttimer-history/:id',
        true,
        [1, 2, 4],
      ),
      route('ParttimerAdd', { default: 'Parttimer/Add' }, '/parttimer', true, [
        1,
        2,
      ]),
      route(
        'ParttimerTimeSheet',
        { default: 'Parttimer/TimeSheet' },
        '/parttimer/timesheet',
        true,
        [1, 2],
      ),
      route(
        'CollegesManager',
        { default: 'Parttimer/Colleges' },
        '/parttimer-colleges',
        true,
        [1, 2],
      ),
      route(
        'VisaManager',
        { default: 'Parttimer/VisaManager' },
        '/parttimer-visa',
        true,
        [1],
      ),
      route(
        'DocumentStatus',
        { default: 'Parttimer/DocumentStatus' },
        '/parttimer-document-status',
        true,
        [1, 2],
      ),
      route(
        'ParttimerTimeSheetEvaluation',
        { default: 'Parttimer/TimeSheetEvaluation' },
        '/parttimer/timesheet/evaluation',
        true,
        [1, 2],
      ),
      route(
        'ParttimerSignupWorktime',
        { default: 'Parttimer/WorktimeSignup' },
        '/parttimer-worktime-signup',
        true,
        [1, 2, 4],
      ),
      route(
        'ParttimerWorktimeView',
        { default: 'Parttimer/WorktimeView' },
        '/parttimer-worktime-view',
        true,
        [1, 2, 4],
      ),
      route(
        'CustomerList',
        { default: 'Customer/Index' },
        '/customer-list',
        true,
        [1, 2],
      ),
      route('CustomerAdd', { default: 'Customer/Add' }, '/customer', true, [1]),
      route(
        'CustomerDetail',
        { default: 'Customer/Detail' },
        '/customer-detail/:id',
        true,
        [1, 2],
      ),
      route(
        'CustomerImportCSV',
        { default: 'Customer/ImportCSV' },
        '/customer-list/import',
        true,
        [1],
      ),
      route(
        'TimeSheetEmployee',
        { default: 'TimeSheetEmployee/Index' },
        '/time-sheet-employee',
        true,
        [1, 2],
      ),
      route(
        'TimeSheetEmployeeBulkUpdate',
        { default: 'TimeSheetEmployee/BulkUpdateTimesheetEmployee' },
        '/time-sheet-employee/bulk-update',
        true,
        [1, 2],
      ),
      route(
        'ShiftInformationDisplay',
        { default: 'Shift/InformationDisplay' },
        '/shift-information-display',
        true,
        [1, 2, 3],
      ),
      route(
        'ShiftInformationDisplay',
        { default: 'Shift/InformationDisplay' },
        '/shift-information-display/:id',
        true,
        [1, 2, 3],
      ),
      route(
        'ShiftAllotment',
        { default: 'Shift/ShiftAllotment' },
        '/shift-information-display/:id/allotment',
        true,
        [1, 2],
      ),
      route(
        'ShiftImportParttimer',
        { default: 'Shift/ImportParttimerToShift' },
        '/shift-information-display/import-parttime',
        true,
        [1, 2],
      ),
      route(
        'ShiftAssignment',
        { default: 'Shift/ShiftAssignment' },
        'shift-assignment',
        true,
        [1, 2],
      ),
      route(
        'ShiftList',
        { default: 'Shift/Index' },
        '/shift-list',
        true,
        [1, 2, 3],
      ),
      route(
        'TimesheetDisplay',
        { default: 'TimesheetDisplay/Index' },
        '/time-sheet-display',
        true,
        [1, 2],
      ),

      route(
        'TimeSheetDepartment',
        { default: 'TimeSheetDepartment/Index' },
        '/time-sheet-department',
        true,
        [1, 2], // customer , employee , admin ?
      ),
      route(
        'TimeSheetDepartmentBulkUpdate',
        { default: 'TimeSheetDepartment/BulkUpdateTimesheetDepartment' },
        '/time-sheet-department/bulk-update',
        true,
        [1, 2],
      ),
      route(
        'TimeKeepingExport',
        { default: 'TimeKeepingExport/index' },
        '/time-keeping-export',
        true,
        [1, 2],
      ),
      route(
        'ListTimeSheet',
        { default: 'TimeSheet/Index' },
        '/time-sheet',
        true,
        [1, 2, 3],
      ),
      route(
        'ListTimeSheet',
        { default: 'TimeSheet/DetailTimeSheet' },
        '/detail-time-sheet',
        true,
        [1, 2, 3],
      ),
      route('Sign', { default: 'Sign/Index' }, '/sign-test', true, [1, 2]),
      route(
        'reportPreview',
        { default: 'Sign/reportPreview' },
        '/report-preview/:id',
        true,
        [1, 2],
      ),
      route('Profile', { default: 'Account/Index' }, '/profile', true),
      route(
        'SchoolVacation',
        { default: 'School/RegisterVacation' },
        '/college-holiday/:id',
        true,
        [1, 2],
      ),
      route('PlanAssign', { default: 'Sign/Plan' }, '/plan-assign', true, [
        1,
        2,
      ]),
      route('FactoryWorkManagement', { default: 'Sign/FactoryWorkManagement' }, '/factory-work-assign', true, [
        1,
        2,
      ]),
      route('JobList', { default: 'Sign/JobsList' }, '/jobs', true, [1, 2]),
      route('DetailJob', { default: 'Sign/DetailJob' }, '/job/:id', true, [1, 2]),
      route('ListDocument', { default: 'Document/Index' }, '/document', true, [1, 2]),
      route('JobList', { default: 'Sign/DetailJob' }, '/jobs/job-edit/:id', true, [1, 2]),
      route('JobList', { default: 'Sign/AddJob' }, '/jobs/job-add', true, [1, 2]),
      route('DetailJob', { default: 'Sign/DetailJob' }, '/jobs/job-edit/:id', true, [1, 2]),
      route('CreateJob', { default: 'Sign/AddJob' }, '/jobs/job-add', true, [1, 2]),
      route('Management28h', { default: 'TimeSheet/Management28h' }, '/28h-manage', true, [1, 2]),
      route('DetailJob', { default: 'Sign/DetailJob' }, '/job/:id', true, [1, 2]),
      route('RevenueSalary', { default: 'RevenueManagement/RevenueList' }, '/revenue-management', true, [1, 2]),
      route('RevenueManagementDetail', { default: 'RevenueManagement/RevenueDetail' }, '/revenue-management/:id', true, [1, 2]),
      route('RevenueManagementHistory', { default: 'RevenueManagement/RevenueHistory' }, '/revenue-history/:id', true, [1, 2]),
      route('RevenueManagement', { default: 'RevenueManagement/RevenueAdd' }, '/revenue-add/:id', true, [1, 2]),
      route('RevenueManagement', { default: 'RevenueManagement/RevenueEdit' }, '/revenue-edit/:id', true, [1, 2]),
      route('RevenueManagement', { default: 'RevenueManagement/RevenueFactories' }, '/revenue-management-factories', true, [1, 2]),
      route('JobList', { default: 'RiceFactoryJob/index' }, '/rice-factory-jobs', true, [1]),
      route('CreateJob', { default: 'RiceFactoryJob/create' }, '/rice-factory-create', true, [1]),
      route('DetailJob', { default: 'RiceFactoryJob/detail' }, '/rice-factory-jobs/detail/:id', true, [1]),
      route(
        'ParttimerList',
        { default: 'ParttimerRice/index' },
        '/parttimer-list-rice',
        true,
        [1, 2, 5],
      ),
      route(
        'ParttimerListDetail',
        { default: 'ParttimerRice/Detail' },
        '/parttimer-detail-rice/:id',
        true,
        [1, 2, 5],
      ),
      route('ParttimerAdd', { default: 'ParttimerRice/Add' }, '/parttimer-rice', true, [
        1,
        2,
        5,
      ]),
      route(
        'Fixshift',
        { default: 'Fixshift' },
        '/fix-shift/:id',
        true,
        [1, 2, 5],
      ),
      route(
        'EvaluationFullTime',
        { default: 'Employee/FullTime' },
        '/evaluation-fulltime',
        true,
        [1],
      ),
      route(
        'EvaluationPartTime',
        { default: 'Employee/PartTime' },
        '/evaluation-parttime',
        true,
        [2],
      ),
      route('SalaryManagementDetail', { default: 'SalaryManagement/SalaryDetail' }, '/salary-management/:id', true, [1, 2]),
      route('SalaryManagementHistory', { default: 'SalaryManagement/SalaryHistory' }, '/salary-history/:id', true, [1, 2]),
      route('SalaryManagement', { default: 'SalaryManagement/SalaryAdd' }, '/salary-add/:id', true, [1, 2]),
      route('SalaryManagement', { default: 'SalaryManagement/SalaryEdit' }, '/salary-edit/:id', true, [1, 2]),
      route(
        'DashboadFulltime',
        { default: 'DashboadFulltime/index' },
        '/dashboad-fulltime',
        true,
        [1],
      ),
      route(
        'FixedList',
        { default: 'Shift/ListFixedShift' },
        '/shift-fixed-list',
        true,
        [1, 2],
      ),
      route(
        'FixedAttendanceList',
        { default: 'Shift/FixedAttendanceList' },
        '/fixed-attendance-list',
        true,
        [1, 2],
      ),
    ]),

    layout('Special', [
      route('Login', { default: 'login/index' }, '/login', false),
      route('Notfound', { default: 'Notfound' }, '/notfound', false),
      route('Forbidden', { default: 'Forbidden' }, '/forbidden', false),
    ]),
  ],
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'ShiftInformationDisplay' && to.name !== 'ShiftAllotment') {
    store.dispatch('shift/updateSelectedShiftPeriod', {
      selectedShiftPeriod: [],
    })
  }
  if (localStorage.getItem('user')) {
    const roles = to.meta.role
    const userRole = JSON.parse(localStorage.getItem('user')).detail.type
    if (roles.length !== 0) {
      if (userRole === 1) {
      } else if (!roles.includes(userRole)) {
        next({
          name: 'Forbidden',
          query: { redirect: to.fullPath },
        })
      }
    }
  }

  if (to.name === 'Login') {
    if (store.state.user.logged) {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requireAuthentication)) {
    if (!store.state.user.logged) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
