import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  list: [],
  error: '',
  customers: [],
  divisions: [],
  shifts: [],
  jobs: [],
}

const mutations = make.mutations(state)

const getters = make.getters(state)

const actions = {
  ...make.actions(state),
  getTimeSheets: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    let fullUrl = api.baseUrl + '/attendence-record'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('list', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getCustomers: ({ commit }) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/customer'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('customers', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getDivisons: ({ commit }, id) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/customer/' + id + '/job'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('divisions', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getShift: ({ commit }, id) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/job/' + id + '/shift'

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('shifts', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getJobs: ({ commit }, id) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/customer/' + id + '/job'

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('jobs', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  handleAttendenceRecord: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    const customerId = payload.customerId
    const workdate = payload.workdate
    const records = payload.records
    delete payload.customerId
    delete payload.workdate
    Vue.axios
      .post(api.baseUrl + '/attendence-record/bundle' + '?customerId=' + customerId + '&workdate=' + workdate, records)
      .then(response => {
        commit('status', 'success')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
