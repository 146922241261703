<template>
  <v-dialog
    v-model="dialogDeleteShift"
    persistent
    width="500"
  >
    <v-card>
      <v-card-text>
        <div class="pt-6 mb-4">
          <v-row>
            <v-col
              cols="4"
              class="text-right"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#e2edfc"
              >
                <v-icon
                  color="indigo"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="7"
            >
              <div class="font-weight-bold">
                この操作は元に戻りません。
              </div>
              <div class="font-weight-bold">
                本当に削除してよろしいですか？
              </div>
            </v-col>
          </v-row>
        </div>
        <div
          class="d-flex justify-center"
        >
          <v-btn
            depressed
            color="error"
            class="mr-4 font-weight-bold"
            @click="removeShift()"
          >
            削除
          </v-btn>
          <v-btn
            color="primary"
            outlined
            text
            class="font-weight-bold"
            @click="closeDialog()"
          >
            キャンセル
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogDeleteShift',
    props: {
      dialogDeleteShift: Boolean,
      index: Number,
    },
    methods: {
      removeShift () {
        this.$emit('removeShift')
        this.closeDialog()
      },
      closeDialog () {
        this.$emit('closeDialogDelete')
      },
    },
  }
</script>

<style lang="sass" scoped>

</style>
