import Vue from 'vue'
// Utilities
import { make } from 'vuex-pathify'
import { api } from '@/config/index'
// Data
const state = {
  status: '',
  message: '',
  jobDetail: '',
  jobListByCustomer: [],
  shiftList: [],
  shiftPeriodList: [],
  periodShiftAssign: [],
  error: '',
  selectedShiftPeriod: [],
  shiftPeriod: {},
  attendenceRecordsLength: 0,
  timestampList: [],
  listAttendenceRecord: {},
  total: 0,
  attendanceList: [],
  attendanceTotal: 0,
  listAssigned: [],
}

const mutations = make.mutations(state)

const actions = {
  importParttimerCSV: ({ commit }, payload) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/attendence-record/bundle'

    Vue.axios
      .put(fullUrl, payload)
      .then(response => {
        if (
          response.status === 200 &&
          response.data.data.attendenceRecords.length !== 0
        ) {
          commit('status', 'success')
        }
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  getShiftsByEmployee: ({ commit }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/user/employee/' + payload.id
    delete payload.id

    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        if (response.data.totalItems) {
          commit('totalItems', response.data.totalItems)
        }
        commit('list', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  getShiftByJob: ({ commit }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/shift'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('shiftList', response.data.data.shifts)
      })
      .catch(error => {
        console.log(error)
        commit('status', 'error')
        commit('error', 'error.server_error')
      })
  },
  getShift: ({ commit }, id) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/job/' + id + '/shift'

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('shiftList', response.data)
        return response.data.data.itemsShift
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getPeriodShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('shiftPeriodList', [])
    let fullUrl = api.baseUrl + '/period-shift'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('shiftPeriodList', response.data.data.periodShifts)
        if (response.data.data.total) {
          commit('total', response.data.data.total)
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getPeriodTimestamps: ({ commit }, id) => {
    commit('status', 'loading')
    commit('timestampList', [])
    const fullUrl = api.baseUrl + '/period-shift/' + id + '/timestamps'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('timestampList', response.data.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  createPeriodShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', [])
    Vue.axios
      .post(api.baseUrl + '/period-shift', payload)
      .then(response => {
        commit('status', '')
        commit('message', 'successCreate')
        commit('shiftPeriod', response.data.data.periodShift)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
          commit('message', 'error')
          commit('error', error.response.data.error)
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },

  submitPeriodShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', [])
    Vue.axios
      .post(api.baseUrl + '/period-shift/' + payload.id + '/submit')
      .then(response => {
        commit('status', '')
        commit('message', 'successUpdate')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  updatePeriodShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', [])
    const fullUrl = api.baseUrl + '/period-shift/' + payload.shiftId
    delete payload.shiftId

    Vue.axios
      .post(fullUrl, payload)
      .then(response => {
        commit('status', '')
        commit('message', 'successUpdate')
        commit('selectedShiftPeriod', [])
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
          commit('message', 'error')
          commit('error', error.response.data.error)
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  deletePeriodShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', [])
    const fullUrl = api.baseUrl + '/period-shift/' + payload.shiftId
    delete payload.shiftId

    Vue.axios
      .delete(fullUrl)
      .then(response => {
        commit('status', '')
        commit('message', 'successDelete')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
          commit('message', 'error')
          commit('error', error.response.data.error)
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  getDetailPeriodShift: ({ commit }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/period-shift/' + payload.id
    delete payload.id
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('shiftPeriod', response.data.data.periodShift)
        commit('attendenceRecordsLength', response.data.data.periodShift.attendenceRecords.length)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getAtendenceByShift: ({ commit, dispatch, state }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/atendence'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('shiftList', response.data)
      })
      .catch(error => {
        console.log(error)
        commit('status', 'error')
        commit('error', 'error.server_error')
      })
  },
  getJobsByCustomer: ({ commit, dispatch }, payload) => {
    commit('shiftList', [])
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/job/'
    payload.includeShift = true
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('jobListByCustomer', response.data)
      })
      .catch(error => {
        console.log(error)
        commit('status', 'error')
        commit('error', 'error.server_error')
      })
  },
  getPeriodShiftAssign: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/period-shift/assign'
    payload.includeShift = true
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('total', response.data.data.total)
        commit('periodShiftAssign', response.data.data.assignees)
      })
      .catch(error => {
        console.log(error)
        commit('status', 'error')
        commit('error', 'error.server_error')
      })
  },
  updateSelectedShiftPeriod: ({ commit }, payload) => {
    commit('selectedShiftPeriod', payload.selectedShiftPeriod)
  },
  checkAttendenceRecord: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/attendence-record/check'
    Vue.axios
      .put(fullUrl, payload)
      .then((response) => {
        commit('status', '')
        commit('message', 'success')
        commit('listAttendenceRecord', response.data)
      })
      .catch(error => {
        console.log(error)
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 401) {
          commit('error', 'error.bad_auth1')
        }
        if (error.response.status === 403) {
          commit('error', 'error.bad_auth2')
        }
        if (error.response.status === 500) {
          commit('error', 'error.internet_error')
        }
      })
  },
  deletePeriod: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    return Vue.axios
        .delete(api.baseUrl + '/period-shift/' + payload)
        .then(() => {
          commit('status', '')
          commit('message', 'deleteSuccess')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  getAttendanceList: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/parttime-employee/fixed-schedule/'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    return Vue.axios
        .get(fullUrl)
        .then((res) => {
          commit('status', '')
          commit('attendanceList', res.data.data.fixedShiftSchedule)
          commit('attendanceTotal', res.data.data.total)
        })
        .catch((error) => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  getJobsCustomer: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    const fullUrl = api.baseUrl + '/customer/' + payload.id + '/job'
    return Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        return response.data.data.jobs
      })
      .catch(error => {
        commit('status', '')
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getCustomer: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/customer/' + payload.id
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    return Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        return response.data.data.customer
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  updateFixedShift: ({ commit }, payload) => {
    commit('status', 'loading')
    const id = payload.id
    if (payload.isUpdate) {
      delete payload.id
      delete payload.isUpdate
    }
    return Vue.axios
      .post(api.baseUrl + '/parttime-employee/' + id, payload)
      .then((res) => {
        commit('status', '')
        commit('message', 'updateSuccess')
        return !!res.data.success
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', error.response.data.errors[0].details)
        }
        return false
      })
  },
  getListAssigned: ({ commit }, payload) => {
    commit('listAssigned', payload)
  },
}

const getters = {
  ...make.getters(state),
  getAttendances () {
    state.attendanceList.map((item) => {
      item.workdateFormat = [false, false, false, false, false, false, false]
      item.workdate.map((i) => {
        item.workdateFormat[i] = true
      })
      return item
    })
    return state.attendanceList
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
