<template>
  <v-edit-dialog>
    {{ value }}
    <template v-slot:input>
      <v-text-field
        v-model="data"
        type="number"
        single-line
        :max="28"
        :min="0"
        @keydown.enter.prevent="save"
        @blur="save"
      />
    </template>
  </v-edit-dialog>
</template>

<script>
  export default {
    name: 'CellEdit',
    props: ['value', 'item', 'prop'],
    data () {
      return {
        data: '',
      }
    },
    mounted () {
      this.data = this.value ? this.value : 0
    },
    methods: {
      save () {
        if (event.target.value >= 0 && event.target.value < 29) {
          this.$emit('save', { value: event.target.value ? event.target.value : 0, item: this.item, prop: this.prop })
        } else {
          this.data = this.value ? this.value : 0
        }
      },
    },
  }
</script>

<style scoped>

</style>
