// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: true,
  drawerImage: '',
  mini: false,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = { ...make.getters(state) }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
