<template>
  <v-dialog
    v-model="dialogCheckFile"
    persistent
    max-width="700px"
    @click:outside="closeDiaLog"
  >
    <v-card>
      <v-card-text>
        <v-container>
          <v-row class="mt-5">
            <v-col
              class="text-button"
              cols="3"
            >
              <span>学生証</span>
            </v-col>
            <v-col cols="9">
              <v-row v-if="!isCreateStudent && (studentCardFile.name || studentCardUrl)">
                <v-col cols="8">
                  <a
                    class="colorCustome"
                    @click="openFile(studentCardUrl)"
                  >
                    <span>{{ studentCardFile.name || getFileName(studentCardUrl) }}</span>
                  </a>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="8">
                      <v-btn
                        color="primary"
                        @click="handleDowloadFile('studentCard', studentCardFile.name || getFileName(studentCardUrl))"
                      >
                        <span>ダウンロード</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="grey"
                        @click="deleteFile('studentCart')"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <upload-file
                    prop="studentCard"
                    :file="studentCardFile"
                    :limit="5000000"
                    @save="save"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
              class="text-button"
              cols="3"
            >
              <span>パスポート</span>
            </v-col>
            <v-col cols="9">
              <v-row v-if="!isCreatePassport && (passportFile.name || passportUrl)">
                <v-col cols="8">
                  <a
                    class="colorCustome"
                    @click="openFile(passportUrl)"
                  >
                    <span>{{ passportFile.name || getFileName(passportUrl) }}</span>
                  </a>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="8">
                      <v-btn
                        color="primary"
                        @click="handleDowloadFile('passport', passportFile.name || getFileName(passportUrl))"
                      >
                        <span>ダウンロード</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="grey"
                        @click="deleteFile('passport')"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <upload-file
                    prop="passport"
                    :file="passportFile"
                    :limit="5000000"
                    @save="save"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
              class="text-button"
              cols="3"
            >
              <span>通帳</span>
            </v-col>
            <v-col cols="9">
              <v-row v-if="!isCreateBankCard && (bankCardFile.name || bankCardUrl)">
                <v-col cols="8">
                  <a
                    class="colorCustome"
                    @click="openFile(bankCardUrl)"
                  >
                    <span>{{ bankCardFile.name || getFileName(bankCardUrl) }}</span>
                  </a>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="8">
                      <v-btn
                        color="primary"
                        @click="handleDowloadFile('passbook', bankCardFile.name || getFileName(bankCardUrl))"
                      >
                        <span>ダウンロード</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="grey"
                        @click="deleteFile('bankCard')"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <upload-file
                    prop="bankCard"
                    :file="bankCardFile"
                    :limit="5000000"
                    @save="save"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
              class="text-button"
              cols="3"
            >
              <span>秘密保持契約書</span>
            </v-col>
            <v-col cols="9">
              <v-row v-if="!isCreateSecurity && (securityFile.name || securityUrl)">
                <v-col cols="8">
                  <a
                    class="colorCustome"
                    @click="openFile(securityUrl)"
                  >
                    <span>{{ securityFile.name || getFileName(securityUrl) }}</span>
                  </a>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="8">
                      <v-btn
                        color="primary"
                        @click="handleDowloadFile('secretContract', securityFile.name || getFileName(securityUrl))"
                      >
                        <span>ダウンロード</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="grey"
                        @click="deleteFile('security')"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <upload-file
                    prop="security"
                    :file="securityFile"
                    :limit="5000000"
                    @save="save"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
              class="text-button"
              cols="3"
            >
              <span>履歴書</span>
            </v-col>
            <v-col cols="9">
              <v-row v-if="!isCreateResume && (resumeFile.name || resumeUrl)">
                <v-col cols="8">
                  <a
                    class="colorCustome"
                    @click="openFile(resumeUrl)"
                  >
                    <span>{{ resumeFile.name || getFileName(resumeUrl) }}</span>
                  </a>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="8">
                      <v-btn
                        color="primary"
                        @click="handleDowloadFile('resume', resumeFile.name || getFileName(resumeUrl))"
                      >
                        <span>ダウンロード</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="grey"
                        @click="deleteFile('resume')"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <upload-file
                    prop="resume"
                    :file="resumeFile"
                    :limit="5000000"
                    @save="save"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="text-button"
              cols="12"
            >
              <span>雇用契約</span>
            </v-col>
            <!-- Contracts -->
            <v-col
              v-if="contracts.length !== 0"
              cols="12"
            >
              <v-row
                v-for="(contract, index) in contracts"
                :key="index"
              >
                <v-col
                  v-if="!contract.deleted"
                  cols="12"
                >
                  <v-row>
                    <!-- file not available -->
                    <v-col
                      v-if="!contracts[index].file.name && !contracts[index].url"
                      cols="3"
                    >
                      <v-text-field
                        v-model="contracts[index].name"
                        dense
                        single-line
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col
                      v-if="!contracts[index].file.name && !contracts[index].url"
                      cols="9"
                    >
                      <v-row>
                        <v-col cols="9">
                          <upload-file
                            :prop="{ name: 'contract', index: index }"
                            :file="contracts[index].file"
                            :limit="5000000"
                            @save="save"
                          />
                          <span class="invalid-input-file">{{ invalidFileText }}</span>
                        </v-col>
                        <v-col cols="3">
                          <v-btn
                            small
                            fab
                            dark
                            depressed
                            outlined
                            color="primary"
                            @click="removeContract(index)"
                          >
                            <v-icon dark>
                              mdi-minus
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- file available -->
                    <v-col
                      v-if="contracts[index].file.name || contracts[index].url"
                      cols="3"
                    >
                      <span v-if="!contracts[index].name">
                        <v-text-field
                          dense
                          single-line
                          outlined
                          clearable
                          @blur="handleName(index)"
                        />
                      </span>
                      <span v-else>{{ contracts[index].name }}</span>
                    </v-col>
                    <v-col
                      v-if="contracts[index].file.name || contracts[index].url"
                      cols="9"
                    >
                      <v-row>
                        <v-col cols="8">
                          <a
                            class="colorCustome"
                            @click="openFile(contracts[index].url)"
                          >
                            <span>
                              {{ contracts[index].file.name || getFileName(contracts[index].url) }}
                            </span>
                          </a>
                        </v-col>
                        <v-col cols="4">
                          <v-row>
                            <v-col cols="8">
                              <v-btn
                                v-if="contracts[index].id"
                                color="primary"
                                @click="handleDowloadFile('contract', contracts[index].file.name || getFileName(contracts[index].url), contracts[index].id)"
                              >
                                <span>ダウンロード</span>
                              </v-btn>
                            </v-col>
                            <v-col cols="4">
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="grey"
                                @click="deleteFile('contract', index)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                v-if="!isBtnContract"
                small
                fab
                dark
                depressed
                outlined
                color="primary"
                @click="addNewContract"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex justify-center mb-3">
          <v-col cols="3">
            <v-btn
              block
              color="primary"
              @click="saveFile()"
            >
              <span class="text-h5 font-weight-bold white--text">{{
                $t("button.save")
              }}</span>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              block
              color="grey"
              @click="closeDiaLog"
            >
              <span class="text-h5 font-weight-bold white--text">{{
                $t("button.cancel")
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import UploadFile from './UploadFile'
  import { get } from 'vuex-pathify'
  export default {
    name: 'DialogCheckFile',
    components: {
      UploadFile,
    },
    props: ['dialogCheckFile', 'action', 'isAdd', 'id'],
    data () {
      return {
        data: '',
        valueFile: '',
        url_image: '',
        file_img: '',
        studentCardFile: [],
        studentCardUrl: '',
        passportFile: [],
        passportUrl: '',
        bankCardFile: [],
        bankCardUrl: '',
        securityFile: [],
        securityUrl: '',
        resumeFile: [],
        resumeUrl: '',
        isBtnContract: false,
        contracts: [],
        invalidFileText: '',
        isCreateStudent: true,
        isCreatePassport: true,
        isCreateBankCard: true,
        isCreateSecurity: true,
        isCreateResume: true,
        isCreateContract: true,
        nameContract: '',
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
      }
    },
    computed: {
      ...get('parttimer', ['status', 'message', 'error', 'documents']),
    },
    watch: {
      message (value) {
        if (value === 'uploadFileSuccess') {
          if (this.id) {
            this.$router.push({
              path: '/parttimer-detail/' + this.id,
            })
            this.$emit('clearIdPartTime')
          } else {
            this.$router.push({
              path: '/parttimer-list',
              query: { updateSuccess: true },
            })
          }
          this.closeDiaLog()
        }
      },
      action (value) {
        if (value === 'updateSuccess') {
          this.handleSave()
        }
      },
      id (value) {
        if (value) {
          this.handleSave()
        }
      },
    },
    mounted () {
      if (!this.isAdd) {
        this.getUploadedDocuments()
      }
    },
    methods: {
      save (payload) {
        if (payload.prop === 'studentCard') {
          this.studentCardFile = payload.file
          this.studentCardUrl = payload.urlImage
          this.isCreateStudent = true
        } else if (payload.prop === 'passport') {
          this.passportFile = payload.file
          this.passportUrl = payload.urlImage
          this.isCreatePassport = true
        } else if (payload.prop === 'bankCard') {
          this.bankCardFile = payload.file
          this.bankCardUrl = payload.urlImage
          this.isCreateBankCard = true
        } else if (payload.prop === 'security') {
          this.securityFile = payload.file
          this.securityUrl = payload.urlImage
          this.isCreateSecurity = true
        } else if (payload.prop === 'resume') {
          this.resumeFile = payload.file
          this.resumeUrl = payload.urlImage
          this.isCreateResume = true
        } else if (payload.prop.name === 'contract') {
          for (let i = 0; i < this.contracts.length; i++) {
            if (payload.prop.index === i) {
              if (!this.contracts[i].created) {
                this.contracts[i].file = payload.file
                this.contracts[i].url = payload.urlImage
                this.contracts[i].deleted = false
                this.contracts[i].updated = true
              } else {
                this.contracts[i].file = payload.file
                this.contracts[i].url = payload.urlImage
              }
            }
          }
        }
      },
      saveFile () {
        this.$emit('handleUpdateLengthFiles', this.checkLengthFiles())
        if (this.contracts.find(contract => (!contract.name || !contract.url) && !contract.deleted)) {
          this.showSnackBar('雇用契約の情報を全て入力してください')
        } else {
          this.$emit('closeDialogFile', false)
        }
      },
      checkFileUpload () {
        const arr = []
        if (this.studentCardFile.length === 0 && !this.studentCardUrl) {
          arr.push('studentCard')
        }
        if (this.passportFile.length === 0 && !this.passportUrl) {
          arr.push('passport')
        }
        if (this.bankCardFile.length === 0 && !this.bankCardUrl) {
          arr.push('passbook')
        }
        if (this.securityFile.length === 0 && !this.securityUrl) {
          arr.push('secretContract')
        }
        if (this.resumeFile.length === 0 && !this.resumeUrl) {
          arr.push('resume')
        }
        return arr
      },
      handleSave () {
        this.invalidFileText = ''
        const formData = new FormData()
        formData.append('id', this.$route.params.id ? this.$route.params.id : this.id)
        formData.append('studentCard', this.studentCardFile)
        // formData.append('studentCardUrl', this.studentCardUrl)
        formData.append('passport', this.passportFile)
        // formData.append('passportUrl', this.passportUrl)
        formData.append('passbook', this.bankCardFile)
        // formData.append('passbookUrl', this.bankCardUrl)
        formData.append('secretContract', this.securityFile)
        // formData.append('secretContractUrl', this.securityUrl)
        formData.append('resume', this.resumeFile)

        let fileIndex = 0
        this.contracts.forEach((contract, index) => {
          let actionCreate = false
          let actionUpdate = false
          if (contract.created) {
            actionCreate = true
          }
          if (contract.updated) {
            actionUpdate = true
          }
          formData.append('contracts', contract.file)
          formData.append('contractsData[]', JSON.stringify(
            {
              name: contract.name,
              id: contract.id,
              deleted: contract.deleted,
              created: actionCreate,
              updated: actionUpdate,
              fileIndex: contract.file ? fileIndex++ : null,
            },
          ))
        })
        formData.append('othersData[]', JSON.stringify(this.checkFileUpload()))
        this.$store.dispatch('parttimer/uploadDocuments', formData)
      },
      closeDiaLog () {
        let arr = []
        arr = this.contracts.filter(contract => !contract.created)
        this.contracts = arr
        this.$emit('closeDialogFile', false)
      },
      openFile (url) {
        if (!url.includes('blob:')) {
          url = `${process.env.VUE_APP_STORAGE_URL}/${url}`
        }
        window.open(url, '_blank')
      },
      handleDowloadFile (type, name, id = null) {
        const payload = {
          id: this.$route.params.id ? this.$route.params.id : this.id,
          type: type,
          name: name,
          idFile: id,
        }
        this.$store.dispatch('parttimer/dowloadUploadedDocuments', payload)
      },
      addNewContract () {
        if (this.contracts.length > 9) {
          this.isBtnContract = !this.isBtnContract
        } else {
          this.contracts.push({
            deleted: false,
            created: true,
            name: '',
            file: [],
            url: '',
          })
        }
      },
      removeContract (index) {
        this.isBtnContract = false
        const contract = this.contracts[index]
        contract.deleted = true
        contract.updated = false
        contract.created = false
        this.$set(this.contracts, index, contract)
      },
      deleteFile (param, index, e) {
        if (param === 'studentCart') {
          this.studentCardFile = []
          this.studentCardUrl = ''
        } else if (param === 'passport') {
          this.passportFile = []
          this.passportUrl = ''
        } else if (param === 'bankCard') {
          this.bankCardFile = []
          this.bankCardUrl = ''
        } else if (param === 'security') {
          this.securityFile = []
          this.securityUrl = ''
        } else if (param === 'resume') {
          this.resumeFile = []
          this.resumeUrl = ''
        } else if (param === 'contract') {
          for (let i = 0; i < this.contracts.length; i++) {
            if (index === i) {
              if (!this.contracts[i].created) {
                this.contracts[i].file = []
                this.contracts[i].url = ''
                this.contracts[i].deleted = false
                this.contracts[i].updated = true
              } else {
                this.contracts[i].file = []
                this.contracts[i].url = ''
              }
            }
          }
        }
      },
      getFileName (url) {
        return url.split('/').pop()
      },
      getUploadedDocuments () {
        this.$store.dispatch('parttimer/getUploadedDocuments', this.$route.params.id).then(() => {
          let lengthFile = 0
          if (this.documents.uploadedDocuments.studentCard) {
            this.studentCardUrl = this.documents.uploadedDocuments.studentCard
            this.isCreateStudent = false
            lengthFile = lengthFile + 1
          }
          if (this.documents.uploadedDocuments.passport) {
            this.passportUrl = this.documents.uploadedDocuments.passport
            this.isCreatePassport = false
            lengthFile = lengthFile + 1
          }
          if (this.documents.uploadedDocuments.passbook) {
            this.bankCardUrl = this.documents.uploadedDocuments.passbook
            this.isCreateBankCard = false
            lengthFile = lengthFile + 1
          }
          if (this.documents.uploadedDocuments.secretContract) {
            this.securityUrl = this.documents.uploadedDocuments.secretContract
            this.isCreateSecurity = false
            lengthFile = lengthFile + 1
          }
          if (this.documents.uploadedDocuments.resume) {
            this.resumeUrl = this.documents.uploadedDocuments.resume
            this.isCreateResume = false
            lengthFile = lengthFile + 1
          }

          if (this.documents.uploadedDocuments.contracts.length !== 0) {
            this.documents.uploadedDocuments.contracts.forEach(item => {
              const contract = {
                id: item.id,
                deleted: false,
                updated: false,
                name: item.contractName,
                file: [],
                url: item.contract,
              }

              this.contracts.push(contract)
              this.isCreateContract = false
            })
            lengthFile = lengthFile + 1
          }
          this.$emit('handleUpdateLengthFiles', lengthFile)
        })
      },
      checkLengthFiles () {
        let lengthFile = 0
        if (this.studentCardUrl) {
          lengthFile = lengthFile + 1
        }
        if (this.passportUrl) {
          lengthFile = lengthFile + 1
        }
        if (this.bankCardUrl) {
          lengthFile = lengthFile + 1
        }
        if (this.securityUrl) {
          lengthFile = lengthFile + 1
        }
        if (this.resumeUrl) {
          lengthFile = lengthFile + 1
        }
        if (this.contracts.length !== 0) {
          if (this.contracts.find(contract => contract.deleted === false)) {
            lengthFile = lengthFile + 1
          }
        }

        return lengthFile
      },
      handleName (index) {
        this.contracts[index].name = event.target.value
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>

<style scoped lang="sass">
  .colorCustome
    color: #37ccc5
  .invalid-input-file
    color: red
</style>
