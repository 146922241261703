<template>
  <div>
    <v-file-input
      v-model="fileUpload"
      accept="image/jpeg,image/jpg,image/png,.pdf"
      prepend-icon="mdi-paperclip"
      outlined
      dense
      @change="uploadImage"
    />
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'UploadFile',
    props: ['prop', 'file', 'limit'],
    data () {
      return {
        base_url: '',
        url_default: '',
        url_image: '',
        file_img: '',
        fileUpload: {},
        validateType: ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'],
        snackbarMessage: '',
        snackbar: false,
      }
    },
    watch: {
      file (value) {
        if (value) {
          this.fileUpload = this.file ? this.file : []
        }
      },
    },
    mounted () {
      this.fileUpload = this.file ? this.file : {}
    },
    methods: {
      uploadImage () {
        if (this.fileUpload) {
          if (this.fileUpload.length !== 0) {
            if (this.fileUpload.size > this.limit) {
              this.showSnackBar('ファイルサイズは5MB未満である必要があります。')
              this.url_image = ''
              this.fileUpload = []
              return
            }
            if (!this.validateType.includes(this.fileUpload.type)) {
              this.showSnackBar('JPEG、JPG、PNG、PDFのいずれかファイルのみです。')
              this.url_image = ''
              this.fileUpload = []
              return
            }
            this.url_image = URL.createObjectURL(this.fileUpload)
          }
        } else {
          this.url_image = ''
        }
        this.$emit('save', { urlImage: this.url_image ? this.url_image : '', file: this.fileUpload ? this.fileUpload : [], prop: this.prop })
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .upload{
    max-width: 250px;
    height: 250px;
    border: 1px solid #c3c3c3;
    border-radius: 7px;
    cursor: pointer;
    position: relative;
    input{
      opacity: 0;
      width: 0px;
      height: 0px;
    }
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 7px;
      object-fit: cover;
    }
  }
  .upload:hover + .icon_close{
    display: block;
  }
  .icon_close:hover{
    display: block;
  }
  .icon_close{
    display: none;
    cursor: pointer;
    i{
      position: absolute;
      top: 30px;
      left: 400px;
    }
  }
</style>
