var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center",attrs:{"id":"wrapper"}},[[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"700","height":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[(!_vm.isSubmitted)?_c('img',_vm._g(_vm._b({class:{ 'borderSignPad': _vm.isBorder },attrs:{"width":_vm.displayWidth || '100px',"height":_vm.displayHeight || '100px',"src":_vm.imgData || _vm.defautImg}},'img',attrs,false),on)):_c('img',_vm._b({class:{ 'borderSignPad': _vm.isBorder },attrs:{"width":_vm.displayWidth || '100px',"height":_vm.displayHeight || '100px',"src":_vm.srcImage || _vm.defautImg}},'img',attrs,false))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.dialog)?_c('vue-signature-pad',{ref:"signaturePad",attrs:{"width":"700px","height":"500px","custom-style":{ backgroundColor: 'white' },"options":{
              onBegin: function () {
                _vm.$refs.signaturePad.resizeCanvas()
              },
            }}}):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.deleteSign)?_c('div',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteSignAction}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.undo}},[_c('v-icon',[_vm._v(" mdi-undo ")])],1),_c('div',[_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.isSelecting},on:{"click":_vm.onButtonClick}},[_c('v-icon',[_vm._v(" mdi-upload ")])],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":".jpg, .jpeg, .png"},on:{"change":_vm.onFileChanged}})],1),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_c('v-icon',[_vm._v(" mdi-check ")])],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }