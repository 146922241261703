import Vue from 'vue'

import { commit, make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  listSalary: [],
  listSalaryExport: [],
  salaryDetail: {},
  listJobs: [],
  salaryInfo: {},
  error: '',
  errors: [],
  isOtNightShift: '',
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  getSalaryHistory: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')

    const id = payload.id
    let fullUrl = api.baseUrl + '/salary/list/' + id
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('listSalary', response.data.data.salaries)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.internet_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getDetailSalary: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')

    let fullUrl = api.baseUrl + '/salary/detail'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('isOtNightShift', response.data.data.isOtNightShift)
        commit('salaryDetail', response.data.data.salaries)
        commit('listJobs', response.data.data.listJobs)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.internet_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  create: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('errors', [])
    Vue.axios
      .post(api.baseUrl + '/salary', payload)
      .then((response) => {
        commit('status', '')
        commit('message', 'success')
        commit('salaryInfo', response.data.data.salaryInfo)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
          commit('message', 'error')
          commit('errors', error.response.data.errors)
        }
        if (error.response.status === 500) {
          commit('error', 'error.internet_error')
        }
        if (error.response.status === 401) {
          commit('error', 'error.timeRegisted')
        }
      })
  },
  update: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.id
    delete payload.id
    Vue.axios
      .put(api.baseUrl + '/salary', payload)
      .then((response) => {
        commit('status', '')
        commit('message', 'success')
        commit('salaryInfo', response.data.data.salaryInfo)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 401) {
          commit('error', 'error.timeRegisted')
        }
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
          commit('message', 'error')
          commit('errors', error.response.data.errors)
        }
        if (error.response.status === 500) {
          commit('error', 'error.internet_error')
        }
      })
  },
  getSalaryExport: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')

    const id = payload.id
    let fullUrl = api.baseUrl + '/salary/export/'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('listSalaryExport', response.data.data.listSalary)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.internet_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
