import Vue from 'vue'
// Utilities
import { make } from 'vuex-pathify'
import { api } from '@/config/index'
import router from '@/router/index'
// import axios from 'axios'

function BadInputException (message) {
  const error = new Error(message)
  error.code = 400
  return error
}

BadInputException.prototype = Object.create(Error.prototype)
const state = {
  user: {},
  userDetail: {},
  logged: false,
  token: {},
  status: '',
  message: '',
  dark: false,
  profile: {},

  drawer: {
    image: 0,
    gradient: 0,
    mini: false,
  },
  gradients: [
    'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
    'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
    'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
  ],
  images: [
    'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
    'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
    'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
  ],
  notifications: [],
  settings: null,
  rtl: false,

  changed: '',
  error: '',
  code: '',
}

const mutations = make.mutations(state)

const actions = {
  logout: ({ commit }) => {
    // Update data
    localStorage.setItem('user', '')
    commit('logged', false)
    commit('user', '')
    // redirect to dashboard
    router.replace('/login')

    // Edit after api ok

    // Vue.axios
    //   .post(api.baseUrl + '/logout')
    //   .then(response => {
    //     commit('user', '')
    //     commit('token', '')
    //     commit('logged', false)
    //     localStorage.setItem('user', '')
    //     // redirect to dashboard
    //     router.replace('/login')
    //   })
    //   .catch(error => {
    //     commit('status', '')
    //     if (error.response) {
    //       // if (error.response.status === 400) {
    //       //   commit('message', 'messages.login_failed')
    //       // } else if (error.response.status === 500) {
    //       //   commit('message', 'messages.server_error')
    //       // }
    //     } else {
    //       commit('message', 'messages.server_error')
    //     }
    //   })
  },
  login: ({ commit }, payload) => {
    commit('status', 'loading')
    // commit('logged', true)
    // router.push('/')

    Vue.axios
      .post(api.baseUrl + '/login', JSON.stringify(payload))
      .then(response => {
        // End login and update status is empty (done)
        commit('logged', true)
        commit('status', '')
        // Update data
        // Unpack token
        var base64Url = response.data.data.token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join(''),
        )
        const localUser = {
          detail: response.data.data.user,
          data: jsonPayload,
          token: response.data.data.token,
        }
        commit('user', localUser)
        commit('token', response.data.data.token)
        localStorage.setItem('user', JSON.stringify(localUser))
        router.push('/')
      })
      .catch(error => {
        commit('status', '')
        commit('logged', false)

        if (error.response.status === 401) {
          commit('message', 'validator.false_auth')
        } else if (error.response.status === 404) {
          commit('message', 'validator.auth_incorrect')
        } else {
          commit('message', 'validator.server_error')
        }
      })
  },
  changePassword: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    Vue.axios
      .put(api.baseUrl + '/user/password', payload)
      .then(response => {
        commit('status', '')
        commit('message', 'updateSuccess')
      })
      .catch(error => {
        commit('status', '')
        if (error.response) {
          if (error.response.status === 400) {
            commit('message', 'パスワードが正しくありません。')
          } else if (error.response.status === 500) {
            commit('message', 'validator.server_error')
          }
        } else {
          commit('message', 'validator.server_error')
        }
      })
  },
  updateProfile: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    Vue.axios
      .put(api.baseUrl + '/user/profile', payload)
      .then(response => {
        commit('status', '')
        commit('message', 'updateSuccess')
      })
      .catch(error => {
        commit('status', '')

        if (error.response) {
          if (error.response.status === 400) {
            commit('message', 'validator.false_auth')
          } else if (error.response.status === 500) {
            commit('message', 'validator.server_error')
          }
        } else {
          commit('message', 'validator.server_error')
        }
      })
  },
  getProfile: ({ commit }, payload) => {
    commit('status', 'loading')
    Vue.axios
      .get(api.baseUrl + '/user/profile')
      .then(response => {
        commit('status', '')
        commit('profile', response.data.data.profile)
      })
      .catch(error => {
        commit('status', '')
        if (error.response) {
          if (error.response.status === 400) {
            commit('message', 'validator.false_auth')
          } else if (error.response.status === 500) {
            commit('message', 'validator.server_error')
          }
        } else {
          commit('message', 'validator.server_error')
        }
      })
  },
}
const getters = {
  dark: (state, getters) => {
    return state.dark || getters.gradient.indexOf('255, 255, 255') === -1
  },
  gradient: state => {
    return state.gradients[state.drawer.gradient]
  },
  image: state => {
    return state.drawer.image === ''
      ? state.drawer.image
      : state.images[state.drawer.image]
  },
  status: state => {
    return state.status
  },
  code: state => {
    return state.code
  },
  user: state => {
    return state.user
  },
  settings: state => {
    return state.settings
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
