import Vue from 'vue'
// Utilities
import { make } from 'vuex-pathify'
import { api } from '@/config/index'
// import { delete } from 'vue/types/umd'
// import axios from 'axios'

const state = {
  totalEmployees: 0,
  totalJobsPartime: 0,
  totalCustomers: 0,
  totalJobs: 0,
  status: '',
  message: '',
  error: '',
  statusCode: '',
}

const mutations = make.mutations(state)
const actions = {
  getTotalCountParttimer: ({ commit }) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalJobsPartime', 0)

    Vue.axios
      .get(
        api.baseUrl + '/parttime-employee/count', // temp
      )
      .then(response => {
        commit('status', '')
        commit('totalJobsPartime', response.data.data.count)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  getTotalCountEmployees: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalEmployees', 0)
    let fullUrl = api.baseUrl + '/employee'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(
        fullUrl, // temp
      )
      .then(response => {
        commit('status', '')
        commit('totalEmployees', response.data.data.employees.length)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getTotalCountCustomers: ({ commit }) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalCustomers', 0)
    commit('status', 'loading')

    Vue.axios
      .get(
        api.baseUrl + '/customer/count', // temp
      )
      .then(response => {
        commit('status', '')
        commit('totalCustomers', response.data.data.count)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },

}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
