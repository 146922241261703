<template>
  <div
    id="wrapper"
    class="text-center"
  >
    <template>
      <div class="text-center">
        <v-dialog
          v-model="dialog"
          width="700"
          height="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <img
                v-if="!isSubmitted"
                :width="displayWidth || '100px'"
                :height="displayHeight || '100px'"
                :src="imgData || defautImg"
                :class="{ 'borderSignPad': isBorder }"
                v-bind="attrs"
                v-on="on"
              >
              <img
                v-else
                :width="displayWidth || '100px'"
                :height="displayHeight || '100px'"
                :class="{ 'borderSignPad': isBorder }"
                :src="srcImage || defautImg"
                v-bind="attrs"
              >
            </div>
          </template>

          <v-card>
            <vue-signature-pad
              v-if="dialog"
              ref="signaturePad"
              width="700px"
              height="500px"
              :custom-style="{ backgroundColor: 'white' }"
              :options="{
                onBegin: () => {
                  $refs.signaturePad.resizeCanvas()
                },
              }"
            />

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <div v-if="deleteSign">
                <v-btn
                  color="primary"
                  text
                  @click="deleteSignAction"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </div>
              <v-btn
                color="primary"
                text
                @click="undo"
              >
                <v-icon>
                  mdi-undo
                </v-icon>
              </v-btn>

              <div>
                <v-btn
                  color="primary"
                  text
                  :loading="isSelecting"
                  @click="onButtonClick"
                >
                  <v-icon>
                    mdi-upload
                  </v-icon>
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  @change="onFileChanged"
                >
              </div>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>
<script>
  import { VueSignaturePad } from 'vue-signature-pad'
  export default {
    name: 'MySignaturePad',
    components: {
      VueSignaturePad,
    },
    model: {
      prop: 'img',
    },
    props: {
      index: Number,
      deleteSign: Boolean,
      block: Boolean,
      label: String,
      displayHeight: Number,
      displayWidth: Number,
      allowRole: Number,
      srcImage: String,
      isSubmitted: Boolean,
      isBorder: Boolean,
      item: Object,
      actionsDelete: Object,
    },
    data () {
      return {
        selectedFile: null,
        isSelecting: false,
        dialog: '',
        imgData: this.value,
        defautImg:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=',
      }
    },
    computed: {},
    watch: {
      selectedFile (value) {
        var reader = new FileReader()
        reader.onloadend = () => {
          const data = reader.result
          this.imgData = data
          this.dialog = false
          this.$emit('save', data)
        }

        reader.readAsDataURL(value)
      },
      imgData (value) {
        this.$emit('getSignature', value)
        this.$emit('getSignatureArray', { data: value, item: this.item })
      },
      srcImage (value) {
        if (value) {
          this.imgData = value
        }
      },
    },
    methods: {
      undo () {
        this.$refs.signaturePad.undoSignature()
      },
      save () {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
        this.imgData = data
        this.dialog = false
        this.$emit('save', data)
      },

      onButtonClick () {
        this.isSelecting = true
        window.addEventListener(
          'focus',
          () => {
            this.isSelecting = false
          },
          { once: true },
        )

        this.$refs.uploader.click()
      },
      deleteSignAction () {
        this.$emit('deleteSignature', this.index)
        this.dialog = false
      },
      onFileChanged (e) {
        this.selectedFile = e.target.files[0]
      },
    },
  }
</script>
<style lang="css">
img:hover {
  border: 1.25px solid #000;
}
wrapper {
  display: block;
}

.borderSignPad {
  border: 1px solid;
  border-radius: 5px;
}
</style>
