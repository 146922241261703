<template>
  <v-container class="pt-10">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="elevation-1"
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 100],
        showFirstLastPage: false,
        'items-per-page-text': '',
      }"
    >
      <template slot="no-data">
        {{ $t("table.messages.no_data") }}
      </template>
      <template v-slot:[`item.index`]="props">
        {{ props.index + 1 }}
      </template>
      <template
        v-slot:[`item.totalWorkhour`]="props"
        no-gutter
      >
        <span> {{ Math.round(props.item.totalWorkhour * 100) / 100 }} </span>
      </template>
      <template
        v-slot:[`item.isChecked`]="props"
        no-gutter
      >
        <v-simple-checkbox
          v-model="props.item.isChecked"
          color="#0F56B3"
          disabled
        />
      </template>
      <slot />
    </v-data-table>
  </v-container>
</template>

<script>
  export default {
    name: 'DatatableExportCsv',

    props: {
      subtitle: String,
      title: String,
      items: Array,
      headers: Array,
      options: Object,
      loading: Boolean,
    },
    computed: {},
    methods: {
      downLoadCsv () {},
    },
  }
</script>
