import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  list: [],
  college: {},
  holiday: [],
  collegeList: [],
  totalCount: 0,
  error: '',
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  getColleagues: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    let fullUrl = api.baseUrl + '/college'
    if (payload) {
      dispatch('getTotalCount')
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
      Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('list', response.data.data.colleges)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
    }
  },
  getColleges: ({ commit }, payload) => {
    commit('status', 'loading')

    let fullUrl = api.baseUrl + '/college'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('collegeList', response.data.data.colleges)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  createCollege: ({ commit }, payload) => {
    const fullUrl = api.baseUrl + '/college/'
    Vue.axios
      .post(fullUrl, payload.options)
      .then(response => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  updateCollege: ({ commit }, payload) => {
    const fullUrl = api.baseUrl + '/college/' + payload.id
    Vue.axios
      .put(fullUrl, payload.options)
      .then(response => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  deleteCollege: ({ commit }, payload) => {
    const fullUrl = api.baseUrl + '/college/' + payload.id
    Vue.axios
      .delete(fullUrl)
      .then(response => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getCollegeHoliday: ({ commit, dispatch }, payload) => {
    let fullUrl = api.baseUrl + '/college/' + payload.id + '/holiday'
    delete payload.id
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('holiday', response.data.data.holidays)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getCollege: ({ commit, dispatch }, payload) => {
    const fullUrl = api.baseUrl + '/college/' + payload.id

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('college', response.data.data.college)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },

  createHoliday: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    Vue.axios
      .post(api.baseUrl + '/holiday', payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  updateHoliday: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    Vue.axios
      .post(api.baseUrl + '/holiday/' + payload.id, payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  deleteHoliday: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    Vue.axios
      .delete(api.baseUrl + '/holiday/' + payload.id)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  importFile: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    return Vue.axios
      .post(api.baseUrl + '/college/import', payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        switch (error.response.status) {
          case 400:
            commit('error', 'error.bad_input')
            break

          case 500:
            commit('error', 'error.duplicate_entity')
            break

          case 404:
            commit('error', 'validator.auth_incorrect')
            break

          default:
            break
        }
      })
  },
}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
