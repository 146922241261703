import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'
import moment from 'moment'
const state = {
  status: '',
  message: '',
  error: '',
  totalPlanneds: [],
  totalActuals: [],
  statistics: {
    employeeCount: 0,
    parttimeCount: 0,
    customerCount: 0,
    jobsCount: 0,
  },
  jobsData: [],
  jobParttimesData: [],
  label: [],
  labelParttime: [],
  totalWorkerPlanned: [],
  totalWorkerActual: [],
}
const mutations = make.mutations(state)

const getters = {
  ...make.getters(state),
  getJobsData () {
    return state.jobsData && state.jobsData.map((i) => {
      return i.count
    })
  },
  getJobParttimesData () {
    return state.jobParttimesData && state.jobParttimesData.map((i) => {
      return i.count
    })
  },
  getLabelJobs () {
    return state.jobsData && state.jobsData.map((i) => {
      return i.count
    })
  },
  getLabelJob () {
    return state.jobsData && state.jobsData.map((i) => {
      return moment(i.date).format('MM月DD日～')
    })
  },
}

const actions = {
  ...make.actions(state),
  getDashboardCustomers: ({ commit }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/customer/' + payload.id + '/get-work-time'
    if (payload) {
      delete payload.id
      const query = new URLSearchParams(payload).toString()
      fullUrl += query ? '?' + query : ''
    }
    return Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('totalPlanneds', response.data.data.workTime.totalPlanned)
        commit('totalActuals', response.data.data.workTime.totalActual)
        commit('totalWorkerActual', response.data.data.workTime.totalWorkerActual)
        commit('totalWorkerPlanned', response.data.data.workTime.totalWorkerPlanned)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getWorkTimeAdmin: ({ commit }, payload) => {
    commit('status', 'loading')
    let fullUrlJob = api.baseUrl + '/admin/get-job-statistics'
    let fullUrlParttime = api.baseUrl + '/admin/get-parttime-statistics'

    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrlJob += query ? '?' + query : ''
      fullUrlParttime += query ? '?' + query : ''
    }
    const promise1 = Vue.axios.get(fullUrlJob)
    const promise2 = Vue.axios.get(fullUrlParttime)
    return Promise.all([promise1, promise2]).then(function (response) {
      commit('status', '')
      commit('jobsData', response[0].data.data)
      commit('jobParttimesData', response[1].data.data)
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
        return
      }
      commit('status', 'error')
      commit('error', 'error.connection_error')
    })
  },
  getMainStatistics: ({ commit }) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/admin/get-main-statistics'
    return Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('statistics', response.data.data)
      })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
