import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  list: [],
  commitSnackBar: '',
  error: '',
  parttimeEmployee: {},
  admins: [],
  totalItems: 0,
  request: {},
}

const mutations = make.mutations(state)

const commitShowSnack = async (response, commit) => {
  let { status, data } = response
  if (data instanceof Blob) {
    data = JSON.parse(await data.text())
  }
  data = data.errors[0]
  let code = 500
  if (status !== 500) {
    code = data.code
  }
  switch (code) {
    case 235:
      commit('commitSnackBar', 'error.bad_input')
      return
    case 240:
      commit('commitSnackBar', 'certificate_paper.error.cant_sign')
      return
    case 237:
      commit('commitSnackBar', 'certificate_paper.error.request_pending')
      return
    case 236:
      commit('commitSnackBar', 'certificate_paper.error.parttimer_not_ready')
      return
    case 238:
      commit('commitSnackBar', 'certificate_paper.error.cant_approve')
      return
    case 239:
      commit('commitSnackBar', 'certificate_paper.error.cant_reject')
      return
    case 241:
      commit('commitSnackBar', 'certificate_paper.error.cant_export')
      return
    default:
      commit('commitSnackBar', 'error.server_error')
  }
}
const actions = {
  ...make.actions(state),
  getDocument: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('commitSnackBar', '')

    let fullUrl = api.baseUrl + '/certificate/request'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('list', response.data.data.rows)
        commit('totalItems', response.data.data.count)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  approvedRequest: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('commitSnackBar', '')
    const url = api.baseUrl + '/certificate/request/' + payload.id + '/admin-signature'
    delete payload.id
    Vue.axios
      .put(url, payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        commitShowSnack(error.response, commit)
      })
  },
  exportRequest: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('commitSnackBar', '')
    const url = api.baseUrl + '/certificate/request/export/' + payload.id
    delete payload.id
    Vue.axios
      .get(url, {
        responseType: 'blob',
      })
      .then(async response => {
        const headers = response.headers
        const contentDisposition = headers['content-disposition'] || ''
        commit('status', '')
        commit('message', 'success')
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'otet/stream' }))
        const link = document.createElement('a')
        const fileName = contentDisposition.split('=')[1] || 'document.pdf'
        link.href = url
        link.setAttribute('download', fileName)
        link.click()
      })
      .catch(error => {
        console.log(error)
        commit('status', error)
        commitShowSnack(error.response, commit)
      })
  },
  getAdmins: ({ commit }) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('commitSnackBar', '')
    const fullUrl = api.baseUrl + '/admin'

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('admins', response.data.data.rows)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  create: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('commitSnackBar', '')
    Vue.axios
      .post(api.baseUrl + '/certificate/request', payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        console.log(error.response)
        commit('status', 'error')
        commitShowSnack(error.response, commit)
      })
  }, // admin create and export

  createAndExport: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('commitSnackBar', '')
    Vue.axios
      .post(api.baseUrl + '/certificate/request/admin', payload)
      .then((res) => {
        commit('status', '')
        commit('message', 'successExport')
        commit('request', res.data.data)
      })
      .catch(error => {
        commit('status', 'error')
        commitShowSnack(error.response, commit)
      })
  },
  adminCreate: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('commitSnackBar', '')
    commit('error', '')
    Vue.axios
      .post(api.baseUrl + '/certificate/request/admin', payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        commitShowSnack(error.response, commit)
      })
  },
  getParttimerEmployee: ({ commit }, payload) => {
    commit('commitSnackBar', '')
    commit('parttimeEmployee', '')
    const id = payload.id
    delete payload.id
    const urlParttime =
      api.baseUrl + '/parttime-employee/' + id + '?isManageId=true'
    Vue.axios
      .get(urlParttime)
      .then(res => {
        commit('parttimeEmployee', res.data.data.parttimeEmployee)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('commitSnackBar', 'error.server_error')
          }

          if (error.response.status === 400) {
            commit('commitSnackBar', 'error.none_id')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  updateParttimeEmployee: ({ commit }, payload) => {
    commit('parttimeEmployee', payload.parttimeEmployee)
  },
  employeeReject: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('commitSnackBar', '')
    Vue.axios
      .get(api.baseUrl + `/certificate/request/${payload.id}/employee-reject`)
      .then(() => {
        commit('status', '')
        commit('message', 'reject')
        commit('commitSnackBar', 'messages.cancelSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        commitShowSnack(error.response, commit)
      })
  },
  getDetailById: async ({ commit }, id) => {
    try {
      commit('error', '')
      const url = `${api.baseUrl}/certificate/request/${id}`
      const { data } = await Vue.axios.get(url)
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  },
  previewPDF: async ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('commitSnackBar', '')
    const url = api.baseUrl + '/certificate/request/preview/' + payload.id
    delete payload.id
    Vue.axios
      .get(url, {
        responseType: 'blob',
      })
      .then(async response => {
        commit('status', '')
        commit('message', 'success')
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob, { oneTimeOnly: true })
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.click()
      //  console.log(response)
      })
      .catch(async error => {
        commit('status', error)
        commitShowSnack(error.response, commit)
      })
  },
}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
