import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  jobDetail: '',
  jobs: '',
  list: [],
  totalCount: 0,
  error: '',
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  getJobsDivisions: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    const fullUrl = api.baseUrl + '/job/' + payload.id + '/division'

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')

        commit('divisions', response.data.data.divisions)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },

  getJobsCustomer: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    const fullUrl = api.baseUrl + '/customer/' + payload.id + '/job'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('list', response.data.data.jobs)
      })
      .catch(error => {
        commit('status', '')
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },

  getJobDetail: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    const fullUrl = api.baseUrl + '/job/' + payload.id

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')

        commit('jobDetail', response.data.data.job)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },

  getJobsList: ({ commit }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/job'
    if (payload) {
      const limit = parseInt(payload.limit)
      const offset = parseInt(payload.offset)
      fullUrl += '?limit=' + limit + '&offset=' + offset
      if (payload.keyword) {
        fullUrl += '&keyword=' + payload.keyword
      }

      if (payload.customerId) {
        fullUrl += '&customerId=' + payload.customerId
      }

      if (payload.isActive) {
        if (payload.isActive === 1) {
          fullUrl += '&isActive=' + true
        } else {
          fullUrl += '&isActive=' + false
        }
      }
    }
    Vue.axios
        .get(fullUrl)
        .then(response => {
          commit('status', '')
          commit('list', response.data)
        })
        .catch(error => {
          commit('status', error)
          commit('error', 'error.server_error')
        })
  },
  createJob: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    Vue.axios
        .post(api.baseUrl + '/job/create', payload)
        .then(response => {
          commit('status', '')
          commit('message', 'success')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  updateJob: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const jobId = payload.jobId
    delete payload.jobId
    Vue.axios
        .put(api.baseUrl + '/job/' + jobId, payload)
        .then(response => {
          commit('status', '')
          commit('message', 'updateSuccess')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  deleteJob: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    Vue.axios
    .delete(api.baseUrl + '/job/' + payload.jobId)
    .then(response => {
      commit('status', '')
      commit('message', 'deleteSuccess')
    })
    .catch(error => {
      commit('status', 'error')
      if (error.response.status === 400) {
        commit('error', 'error.bad_input')
      }
      if (error.response.status === 500) {
        commit('error', 'error.job.assigned')
      }
    })
  },
  pinCustomer: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    Vue.axios
        .post(api.baseUrl + '/job/pinCustomer', payload, {})
        .then(response => {
          commit('status', '')
          commit('message', 'updateSuccess')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  getJobsForCompany: ({ commit }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/job'
    if (payload) {
      const customerId = payload.customerId
      const includeShift = payload.includeShift
      fullUrl += '?customerId=' + customerId + '&includeShift=' + includeShift
      if (payload.keyword) {
        fullUrl += '&keyword=' + payload.keyword
      }
    }
    Vue.axios
        .get(fullUrl)
        .then(response => {
          commit('status', '')
          commit('list', response.data)
        })
        .catch(error => {
          commit('status', error)
          commit('error', 'error.server_error')
        })
  },
  changeStatus: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const jobId = payload.jobId
    delete payload.jobId
    Vue.axios
        .put(api.baseUrl + '/job/' + jobId + '/active', payload, {})
        .then(response => {
          commit('status', '')
          commit('message', 'updateStatus')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  getJob: ({ commit }, payload) => {
    commit('status', 'loading')
    const id = payload.id
    Vue.axios
        .get(api.baseUrl + '/job/' + id)
        .then(response => {
          commit('status', '')
          commit('jobDetail', response.data)
        })
        .catch(error => {
          commit('status', error)
          commit('error', 'error.server_error')
        })
  },
}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
