import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  orders: [],
  error: '',
  jobs: [],
}

const mutations = make.mutations(state)

const getters = make.getters(state)

const actions = {
  ...make.actions(state),
  getOrders: ({ commit }, payload) => {
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/order'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
        .get(fullUrl)
        .then(response => {
          commit('status', '')
          commit('orders', response.data)
        })
        .catch(error => {
          commit('status', error)
          commit('error', 'error.server_error')
        })
  },
  updateJob: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const customerId = payload.customerId
    const jobId = payload.jobId
    const time = payload.date
    delete payload.customerId
    delete payload.jobId
    delete payload.date
    Vue.axios
        .patch(api.baseUrl + '/order/customer/' + customerId + '/' + jobId + '?time=' + time, payload, {})
        .then(response => {
          commit('status', '')
          commit('message', 'updateSuccess')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  updatePinTask: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const customerId = payload.customerId
    const time = payload.time
    Vue.axios
        .get(api.baseUrl + '/order/pin/' + customerId + '?time=' + time, {})
        .then(response => {
          commit('status', '')
          commit('message', 'updateSuccess')
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  getOrdersForCompany: ({ commit }, payload) => {
    commit('status', 'loading')
    const customerId = payload.customerId
    const time = payload.time
    let fullUrl = api.baseUrl + '/order/customer/' + customerId + '?time=' + time
    if (payload.keyword) {
      fullUrl += '&keyword=' + payload.keyword
    }
    Vue.axios
        .get(fullUrl)
        .then(response => {
          commit('status', '')
          commit('jobs', response.data)
        })
        .catch(error => {
          commit('status', error)
          commit('error', 'error.server_error')
        })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
