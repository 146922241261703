<template>
  <v-dialog
    v-model="dialogStatus"
    persistent
    width="500"
  >
    <v-card>
      <v-card-text>
        <div class="pt-6 mb-4">
          <v-row>
            <v-col
              cols="12"
            >
              <div class="font-weight-bold text-center">
                このフィールドを編集すると、他の画面に変更が発生します。
              </div>
              <div class="font-weight-bold text-center">
                本当に「状態」を変更しますか。
              </div>
            </v-col>
          </v-row>
        </div>
        <div
          class="d-flex justify-center"
        >
          <v-btn
            depressed
            color="primary"
            class="mr-4 font-weight-bold"
            @click="save()"
          >
            変更
          </v-btn>
          <v-btn
            color="primary"
            outlined
            text
            class="font-weight-bold"
            @click="closeDialog()"
          >
            キャンセル
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogStatus',
    props: {
      dialogStatus: Boolean,
    },
    methods: {
      save () {
        this.$emit('save', true)
      },
      closeDialog () {
        this.$emit('closeDialogStatus', false)
      },
    },
  }
</script>

<style lang="sass" scoped>

</style>
