import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  error: '',
  jobs: [],
  fixedInfor: null,
  listFixedShift: [],
  total: [],
  periodShift: {},
  itemDayShift: [],
  warningTime: [],
  totalWarning: 0,
}

const mutations = make.mutations(state)

const getters = {
  ...make.getters(state),
  getHeader () {
    return ''
  },
}

const actions = {
  ...make.actions(state),
  createListFixed: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')

    return Vue.axios
        .post(api.baseUrl + '/fixed-shift', payload)
        .then(response => {
          commit('status', '')
          commit('message', 'success')
          commit('fixedInfor', response.data.data)
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', error.response.data.errors[0].details)
          }
        })
  },
  getListDetailShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/fixed-shift'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    return Vue.axios
        .get(fullUrl)
        .then(response => {
          commit('status', '')
          commit('message', 'success')
          commit('listFixedShift', response.data.data.fixedShifts)
          commit('total', response.data.data.total)
          commit('periodShift', response.data.data.periodShift)
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', error.response.data.errors[0].details)
          }
        })
  },
  getListError: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/fixed-shift/over-limit-time'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    return Vue.axios
        .get(fullUrl)
        .then(response => {
          commit('status', '')
          commit('warningTime', response.data.data.warningTime)
          commit('totalWarning', response.data.data.total)
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', error.response.data.errors[0].details)
          }
        })
  },
  deleteShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    return Vue.axios
      .delete(api.baseUrl + '/fixed-shift/' + payload.id)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
        return true
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', error.response.data.errors[0].details)
        }
        return false
      })
  },
  CreateShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    return Vue.axios
      .post(api.baseUrl + '/fixed-shift/single', payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
        return true
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', error.response.data.errors[0].details)
        }
        return false
      })
  },
  UpdateShift: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    return Vue.axios
      .put(api.baseUrl + '/fixed-shift/' + payload.id, payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
        return true
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', error.response.data.errors[0].details)
        }
        return false
      })
  },
  getShiftInDay: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/fixed-shift/day'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    return Vue.axios
      .get(fullUrl)
      .then((response) => {
        commit('status', '')
        commit('itemDayShift', response.data.data.listJobs)
        return true
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', error.response.data.errors[0].details)
        }
        return false
      })
  },
  exportFixedShift: ({ commit }, payload) => {
    let fullUrl = api.baseUrl + '/fixed-shift/export/day'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    return Vue.axios
      .get(fullUrl)
      .then((response) => {
        return response.data.data.result
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
