import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  list: [],
  totalCount: 0,
  error: '',
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  getWorktimes: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    let fullUrl = api.baseUrl + '/worktime'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('list', response.data.data.worktimes)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
