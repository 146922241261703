import Vue from 'vue'
// Utilities
import { make } from 'vuex-pathify'
import { api } from '@/config/index'
import axios from 'axios'

// import { delete } from 'vue/types/umd'
// import axios from 'axios'

const state = {
  status: '',
  message: '',
  list: '',
  listNotFixed: '',
  totalCount: 0,
  totalCountWorkHistory: 0,
  newParttimeEmployee: {},
  parttimeEmployee: {},
  employeeEvaluation: {},
  error: '',
  colleges: [],
  parttimeInfo: {},
  resultTimeShift: [],
  workTimeList: [],
  unavailbleTimes: [],
  employeesWorktimes: [],
  closeCamera: false,
  dataOcr: {},
  dataOcrSave: {},
  requestBankList: [],
  mailsList: [],
  newPassword: '',
  resetPassword: {},
  parttimerVisaList: {},
  token: '',
  list28hManagement: [],
  documents: {},
}
const mutations = make.mutations(state)
const actions = {
  create: ({ commit }, payload) => {
    commit('status', 'loading')
    Vue.axios
      .post(api.baseUrl + '/parttime-employee', payload)
      .then((response) => {
        commit('status', '')
        commit('message', 'success')
        commit('newParttimeEmployee', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  submitCSV: ({ commit }, payload) => {
    commit('status', 'loading')
    Vue.axios
      .post(api.baseUrl + '/parttime-employee/bundle', payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  update: ({ commit }, payload) => {
    commit('status', 'loading')
    Vue.axios
      .post(api.baseUrl + '/parttime-employee/' + payload.id, payload)
      .then((response) => {
        commit('status', '')
        commit('message', 'updateSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  delete: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.id
    delete payload.id
    Vue.axios
      .delete(api.baseUrl + '/parttime-employee/' + id)
      .then(() => {
        commit('status', '')
        commit('message', 'deleteSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  getParttimers: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/parttime-employee'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('list', response.data.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getParttimerNotFixed: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalCount', 0)
    dispatch('getTotalCount')
    let fullUrl = api.baseUrl + '/parttime-employee?isFixed=false'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('listNotFixed', response.data.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getParttimerDetail: ({ commit }, payload) => {
    commit('status', 'loading')
    if (payload.id) {
      Vue.axios
        .get(api.baseUrl + '/parttime-employee/' + payload.id)
        .then(response => {
          commit('status', '')
          commit('parttimeEmployee', response.data)
          if (response.data.data.parttimeEmployee) {
            commit('parttimeEmployee', response.data.data.parttimeEmployee)
          }
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response) {
            if (error.response.status === 400) {
              commit('error', 'error.user_not_found')
            }
            return
          }
          commit('error', error.response)
        })
    }
  },
  getParttimerEmployee: ({ commit }, payload) => {
    commit('error', '')
    const id = payload.id
    delete payload.id
    const urlParttime =
      api.baseUrl + '/parttime-employee/' + id + '?isManageId=true'
    Vue.axios
      .get(urlParttime)
      .then(res => {
        commit('parttimeEmployee', res.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }

          if (error.response.status === 400) {
            commit('error', 'error.none_id')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getTotalCount: ({ commit }) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')

    Vue.axios
      .get(
        api.baseUrl + '/parttime-employee/count', // temp
      )
      .then(response => {
        commit('status', '')
        commit('totalCount', response.data.data.count)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  getCollege: ({ commit }) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/college'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('colleges', response.data.data.colleges)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getUnavailableTimeByParttimeEmployeeId: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl =
      api.baseUrl + '/parttime-employee/' + payload.id + '/unavailble-time'

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')

        commit('unavailbleTimes', response.data.data.unavailbleTime)
      })
      .catch(error => {
        if (error.response) {
          if (error.status === 400) {
            commit('status', 'error')
            commit('error', 'error.bad_input')
          }
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  postUnavailableTimeByParttimeEmployeeId: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/unavailable-time/bundle'
    Vue.axios
      .post(fullUrl, payload)
      .then(response => {
        commit('status', 'success')
      })
      .catch(error => {
        if (error.response) {
          if (error.status === 400) {
            commit('status', 'error')
            commit('error', 'error.bad_input')
          }
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getWorkTimeHistory: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/parttime-employee/history/' + payload.id
    if (payload.option) {
      const query = new URLSearchParams(payload.option).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit(
          'totalCountWorkHistory',
          response.data.data.workTimeHistory.count,
        )
        commit('resultTimeShift', response.data.data.workTimeHistory.rows)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getChartData: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/parttime-employee/timeline/'
    if (payload.id) {
      fullUrl += payload.id
      if (payload.type) {
        const query = new URLSearchParams(payload.type).toString()
        fullUrl += '?' + query
      }
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('parttimeInfo', response.data.data.info)
        commit('workTimeList', response.data.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getEmployeeWorktime: ({ commit }, payload) => {
    if (!payload.id) return
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl =
      api.baseUrl + '/attendence-record/period-of-parttime-employee/'

    fullUrl += payload.id
    delete payload.id
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }

    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('employeesWorktimes', response.data.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  deleteUnavailableTimeByParttimeEmployeeId: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.id
    delete payload.id
    Vue.axios
      .delete(api.baseUrl + '/unavailable-time/' + id)
      .then(response => {
        commit('status', '')
        commit('message', 'deleteSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  closeCameraOcr: ({ commit }, payload) => {
    commit('closeCamera', payload)
  },
  getRequestBank: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = api.baseUrl + '/admin/bank-request'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('requestBankList', response.data.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  approachReq: ({ commit }, payload) => {
    commit('status', 'loading')
    const id = payload.id
    delete payload.id
    Vue.axios
      .put(
        api.baseUrl + '/admin/bank-request/' + id,
        payload,
      )
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  cancelReq: ({ commit }, payload) => {
    commit('status', 'loading')
    const id = payload.id
    delete payload.id
    Vue.axios
      .put(
        api.baseUrl + '/admin/bank-request/' + id,
        payload,
      )
      .then(() => {
        commit('status', '')
        commit('message', 'cancelSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  getmailsList: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('mailsList', [])
    let fullUrl = api.baseUrl + '/admin/mail-config'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('mailsList', response.data.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.connection_error')
          }
          return
        }
        commit('status', 'error')
      })
  },
  updateMailsList: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/admin/mail-config'
    Vue.axios
      .put(fullUrl, payload)
      .then(response => {
        commit('status', '')
        commit('message', 'updateMailSuccess')
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.connection_error')
          }
          return
        }
        commit('status', 'error')
      })
  },
  resetPassword: ({ commit }, payload) => {
    const id = payload.id
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/parttime-employee/reset-password/' + id
    Vue.axios
      .post(fullUrl)
      .then((response) => {
        commit('status', '')
        commit('message', 'successChangePass')
        commit('resetPassword', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  getParttimerVisa: ({ commit }, payload) => {
    commit('message', '')
    commit('error', '')
    const id = payload.id
    delete payload.id
    let fullUrl = api.baseUrl + '/parttime-employee/' + id + '/personal-visa'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('parttimerVisaList', response.data.data)
      })
  },
  getNewPass: ({ commit }, payload) => {
    commit('newPassword', payload)
  },
  getTokenOcr: ({ commit }, payload) => {
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/ocr/token'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('token', response.data.data.token)
      })
  },
  getDataOcr ({ commit }, payload) {
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrlOcr + '/ocr/cards/scan'
    const HTTP = axios.create({
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
        'Access-Control-Max-Age': 86400,
        Authorization: 'Bearer ' + state.token,
      },
    })
    HTTP.get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('dataOcr', response.data)
      })
  },
  saveDataOcr ({ commit }, payload) {
    commit('dataOcrSave', payload)
  },

  getList28hManagement: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/parttime-employee/list/over-limited-hours-of-week'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('list28hManagement', response.data.data.parttimeEmployees)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },

  uploadDocuments: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = `${api.baseUrl}/parttime-employee/${payload.get('id')}/upload-documents`
    Vue.axios
        .post(fullUrl, payload)
        .then(response => {
          commit('status', '')
          commit('message', 'uploadFileSuccess')
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 500) {
              commit('error', 'error.duplicate_entity')
            }
            if (error.response.status === 400) {
              commit('error', 'error.bad_input')
            }
            return
          }
          commit('status', 'error')
          commit('error', 'error.connection_error')
        })
  },

  getUploadedDocuments: async ({ commit }, id) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = `${api.baseUrl}/parttime-employee/${id}/upload-documents`
    return Vue.axios
        .get(fullUrl)
        .then(response => {
          commit('documents', response.data.data)
          commit('status', '')
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 500) {
              commit('error', 'error.duplicate_entity')
            }
            return
          }
          commit('status', 'error')
          commit('error', 'error.connection_error')
        })
  },

  updateUnavailableTimeByParttimeEmployeeId: ({ commit }, payload) => {
    commit('status', 'loading')
    const id = payload.id
    delete payload.id
    Vue.axios
      .put(
          api.baseUrl + '/unavailable-time/' + id,
          payload,
      )
      .then(() => {
        commit('status', '')
        commit('message', 'updateSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  dowloadUploadedDocuments: async ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = `${api.baseUrl}/parttime-employee/${payload.id}/download-document?type=${payload.type}`
    if (payload.type === 'contract') {
      fullUrl = `${api.baseUrl}/parttime-employee/${payload.id}/download-document?type=${payload.type}&contractId=${payload.idFile}`
    }
    return Vue.axios({
          url: fullUrl,
          method: 'GET',
          responseType: 'blob',
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', payload.name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 500) {
              commit('error', 'error.duplicate_entity')
            }
            return
          }
          commit('status', 'error')
          commit('error', 'error.connection_error')
        })
  },
}
const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
