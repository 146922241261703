<template>
  <v-edit-dialog>
    <span
      v-if="prop === 'breaktime'"
      :class="{'errorBreakTime':!checkBreakTime}"
      @click="setValueData()"
    >
      {{ converTime(value) }}
    </span>
    <span
      v-else
      :class="{'errorBreakTime':!checkBreakTime}"
      @click="setValueData()"
    >{{ value }}</span>

    <template v-slot:input>
      <validation-provider
        v-slot="{ errors }"
        name="totimeValidation"
        rules="checktime"
      >
        <v-text-field
          v-model="data"
          single-line
          placeholder="hh:mm"
          :error-messages="errors[0]"
          @keydown.enter.prevent="save"
          @blur="save"
        />
      </validation-provider>
    </template>
  </v-edit-dialog>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import moment from 'moment'
  export default {
    name: 'CellEditTime',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: ['value', 'item', 'prop', 'checkBreakTime'],
    data () {
      return {
        data: '',
      }
    },
    watch: {
      data (data) {
        if (data) {
          if (data.length === 1 && parseInt(data) > 4) {
            this.data = '0' + data + ':'
          } else if (data.length === 2 || parseInt(data.slice(0, 2)) >= 48) {
            if (parseInt(data) < 48) {
              this.data = data + ':'
            } else if (parseInt(data.slice(0, 2)) === 48) {
              this.data = '48:00'
            } else {
              this.data = '48:00'
              this.$emit('erorrValidate', '時間は48時間を超えてはなりません')
            }
          }
        }
      },
    },
    mounted () {
      extend('checktime', {
        events: ['blur'],
        validate (value) {
          if (/^[0,1,2,3,4]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/g.test(value)) {
            const timeSplited = value.split(':')
            if (timeSplited[0] === 24) return false
            return true
          }
          return false
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
      extend('checktime24h', {
        events: ['blur'],
        validate (value) {
          if (!moment(value, 'HH:mm').isValid()) return false
          return true
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
      // this.data = this.value ? this.converTime(this.value) : '00:00'
    },
    methods: {
      save () {
        if (/^[0,1,2,3,4]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/g.test(this.data)) {
          if (this.prop === 'breaktime') {
            this.$emit('save', { value: event.target.value ? this.converNumber(event.target.value) : 0, item: this.item, prop: this.prop })
          } else {
            this.$emit('save', { value: this.data ? this.data : 0, item: this.item, prop: this.prop })
          }
        } else {
          if (this.prop === 'breaktime') {
            this.data = this.value ? this.converTime(this.value) : '00:00'
          } else {
            this.data = this.value ? this.value : '00:00'
          }
        }
      },
      setValueData () {
        this.data = this.value ? this.converTime(this.value) : '00:00'
      },
      converNumber (str) {
        const timeStart = parseInt(str.slice(0, 2))
        const timeEnd = (parseInt(str.slice(3, 5)) / 60)

        if (timeStart) {
          return parseFloat(timeStart + timeEnd).toFixed(2)
        } else if (timeEnd) {
          return parseFloat('0' + timeEnd).toFixed(2)
        } else {
          return 0
        }
      },
      converTime (number) {
        if (this.prop === 'breaktime') {
          if (number) {
            let startTime = String(parseInt(number))
            let endTime = String(Math.round((number - parseInt(number)) * 60))
            if (startTime.length === 1) {
              startTime = '0' + startTime + ':'
            } else if (startTime.length === 2) {
              startTime += ':'
            }
            if (endTime.length === 1) {
              endTime = '0' + endTime
            }
            return startTime + endTime
          } else {
            return '00:00'
          }
        } else {
          return number
        }
      },
    },
  }
</script>

<style scoped lang="sass">
.errorBreakTime
  color: #E43E08
</style>
