import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  statusDetail: '',
  message: '',
  totalCount: 0,
  error: '',
  customers: [],
  documentStatuses: [],
  documentStatus: {},
  documentStatusesExport: [],
}

const mutations = make.mutations(state)

const getters = make.getters(state)

const actions = {
  ...make.actions(state),
  getCustomers: ({ commit }) => {
    commit('status', 'loading')
    const fullUrl = api.baseUrl + '/customer'
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('customers', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getDocumentStatus: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalCount', 0)
    let fullUrl = api.baseUrl + '/document-status'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('documentStatuses', response.data.data.documentStatuses)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getDocumentStatusDetail: ({ commit }, payload) => {
    commit('statusDetail', 'loading')
    if (payload.id) {
      Vue.axios
        .get(api.baseUrl + '/document-status/' + payload.id)
        .then(response => {
          commit('statusDetail', '')
          commit('documentStatus', response.data)
          if (response.data.data.documentStatus) {
            commit('documentStatus', response.data.data.documentStatus)
          }
        })
        .catch(error => {
          commit('statusDetail', 'error')
          if (error.response) {
            if (error.response.status === 400) {
              commit('error', 'error.user_not_found')
            }
            return
          }
          commit('error', error.response)
        })
    }
  },
  update: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/document-status/' + payload.id
    Vue.axios
      .put(fullUrl, payload.info)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  getDocumentStatusExport: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalCount', 0)
    let fullUrl = api.baseUrl + '/document-status/export'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('documentStatusesExport', response.data.data.documentStatuses)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
