import Vue from 'vue'
// Utilities
import { make } from 'vuex-pathify'
import { api } from '@/config/index'
// import axios from 'axios'

const state = {
  status: '',
  message: '',
  messageEmployee: '',
  list: '',
  totalCount: 0,
  employeeDetail: {},
  employeeEvaluation: {},
  error: '',
  newEmployee: {},
  newPassword: '',
  resetPassword: {},
  dataDashboadFulltime: {},
  listEvaluateParttime: [],
  newEvaluateParttime: {},
  isFullTime: false,
  count: 0,
  listPositions: [
    { value: 0, text: '役員' },
    { value: 1, text: '次長' },
    { value: 2, text: 'エリアマネージャー' },
    { value: 3, text: '課長' },
    { value: 4, text: '課長代理' },
    { value: 5, text: '主任' },
    { value: 6, text: '副主任' },
    { value: 7, text: '一般' },
  ],
}

const mutations = make.mutations(state)

const actions = {
  create: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    Vue.axios
      .post(api.baseUrl + '/employee', payload)
      .then(response => {
        commit('status', '')
        commit('message', 'success')
        commit('newEmployee', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  update: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.userId
    if (!payload.password) {
      delete payload.password
      delete payload.confirm_password
    }
    delete payload.userId
    Vue.axios
      .put(api.baseUrl + '/employee/' + id, payload, {})
      .then(response => {
        commit('status', '')
        commit('message', 'updateSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  delete: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.userId
    delete payload.userId
    Vue.axios
      .delete(api.baseUrl + '/employee/' + id)
      .then(response => {
        commit('status', '')
        commit('message', 'deleteSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  getEmployees: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalCount', 0)
    dispatch('getTotalCount')
    let fullUrl = api.baseUrl + '/employee'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(
        fullUrl, // temp
      )
      .then(response => {
        commit('status', '')
        if (response.data.totalItems) {
          commit('totalItems', response.data.totalItems)
        }
        commit('list', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getEmployeeDetail: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    if (payload.id) {
      Vue.axios
        .get(
          api.baseUrl + '/employee/' + payload.id, // temp
        )
        .then(response => {
          commit('status', '')
          commit('employeeDetail', response.data.data.employee)
        })
        .catch(error => {
          commit('status', 'error')
          commit('error', error.response)
        })
    }
  },
  getTotalCount: ({ commit }) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')

    Vue.axios
      .get(
        api.baseUrl + '/employee/count', // temp
      )
      .then(response => {
        commit('status', '')
        commit('totalCount', response.data.data.count)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  updateBank: ({ commit }, payload) => {
    commit('status', 'loading')
    const id = payload.id
    delete payload.id
    Vue.axios
      .put(
        api.baseUrl + '/employee/bank-request/' + id,
        payload,
      )
      .then(() => {
        commit('status', '')
        commit('messageEmployee', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  updatePersionalVisa: ({ commit }, payload) => {
    commit('status', 'loading')
    const id = payload.id
    delete payload.id
    Vue.axios
      .put(
        api.baseUrl + '/employee/personal-visa-partime/' + id,
        payload.results,
      )
      .then(() => {
        commit('status', '')
        commit('messageEmployee', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  resetPassword: ({ commit }, payload) => {
    const id = payload.id
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/employee/reset-password/' + id
    Vue.axios
      .post(fullUrl)
      .then((response) => {
        commit('status', '')
        commit('message', 'successChangePass')
        commit('resetPassword', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  getDataDashboadFulltime: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/employee/get-work-time-by-customer?date=' + payload.date
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('dataDashboadFulltime', response.data.data)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  getNewPass: ({ commit }, payload) => {
    commit('newPassword', payload)
  },
  checkFullTime: ({ commit }, isFullTime) => {
    commit('isFullTime', isFullTime)
  },
  getListEvaluateParttime: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    let fullUrl = state.isFullTime ? api.baseUrl + '/employee-rating' : api.baseUrl + '/parttime-employee-rating'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('listEvaluateParttime', response.data.data.ratings.rows)
        commit('count', response.data.data.ratings.count)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  createEvaluateParttime: ({ commit }, payload) => {
      commit('status', 'loading')
      commit('message', '')
      commit('error', '')
      const fullUrl = state.isFullTime ? api.baseUrl + '/employee-rating' : api.baseUrl + '/parttime-employee-rating'
      return Vue.axios
        .post(fullUrl, payload)
        .then(response => {
          commit('status', '')
          commit('message', 'success')
          commit('newEvaluateParttime', response.data)
        })
        .catch(error => {
          commit('status', 'error')
          if (error.response.status === 400) {
            commit('error', 'error.bad_input')
          }
          if (error.response.status === 500) {
            commit('error', 'error.duplicate_entity')
          }
        })
  },
  updateEvaluateParttime: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = state.isFullTime ? api.baseUrl + '/employee-rating' : api.baseUrl + '/parttime-employee-rating'
    return Vue.axios
      .put(fullUrl, payload)
      .then(response => {
        commit('status', '')
        commit('message', 'success')
        commit('newEvaluateParttime', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  deleteEvaluateParttime: ({ commit }, id) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = state.isFullTime ? api.baseUrl + '/employee-rating/' + id : api.baseUrl + '/parttime-employee-rating/' + id
    return Vue.axios
      .delete(fullUrl)
      .then(() => {
        commit('status', '')
        commit('message', 'deleteSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
}
const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
