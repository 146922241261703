import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  list: [],
  totalCount: 0,
  customerDetail: {},
  error: '',
  statusCode: '',
  newCustomer: {},
  newPassword: '',
  resetPassword: {},
  listJobs: [],
  imageUrl: '',
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  getTotalCount: ({ commit }) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalCount', 0)
    commit('status', 'loading')

    Vue.axios
      .get(
        api.baseUrl + '/customer/count', // temp
      )
      .then(response => {
        commit('status', '')
        commit('totalCount', response.data.data.count)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  getCustomer: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalCount', 0)
    commit('status', 'loading')
    let fullUrl = api.baseUrl + '/customer/' + payload.id
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('customerDetail', response.data.data.customer)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error.response)
      })
  },
  submitCSV: ({ commit }, payload) => {
    commit('status', 'loading')
    Vue.axios
      .post(api.baseUrl + '/customer/bundle', payload)
      .then(response => {
        commit('status', 'success')
        commit('message', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        commit('error', error)
      })
  },
  getCustomers: ({ commit, dispatch }, payload) => {
    commit('status', 'loading')

    let fullUrl = api.baseUrl + '/customer'
    if (payload) {
      dispatch('getTotalCount')
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        if (response.data.totalItems) {
          commit('totalItems', response.data.totalItems)
        }
        commit('list', response.data)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.connection_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  create: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    Vue.axios
      .post(api.baseUrl + '/customer', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        commit('status', '')
        commit('message', 'success')
        commit('statusCode', 'success')
        commit('newCustomer', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.connection_error')
        }
      })
  },
  update: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.get('id')
    payload.delete('id')
    Vue.axios
      .put(api.baseUrl + '/customer/' + id, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        commit('status', '')
        commit('message', 'updateSuccess')
        commit('statusCode', 'updateSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.connection_error')
        }
      })
  },
  delete: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const id = payload.userId
    delete payload.userId
    Vue.axios
      .delete(api.baseUrl + '/customer/' + id)
      .then(response => {
        commit('status', '')
        commit('message', 'success')
        commit('statusCode', 'deleteSuccess')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.connection_error')
        }
      })
  },
  updateStatusCodeDefault ({ commit }) {
    commit('statusCode', '')
  },
  resetPassword: ({ commit }, payload) => {
    const id = payload.id
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/customer/reset-password/' + id
    Vue.axios
      .post(fullUrl)
      .then((response) => {
        commit('status', '')
        commit('message', 'successChangePass')
        commit('resetPassword', response.data)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.connection_error')
        }
      })
  },
  getNewPass: ({ commit }, payload) => {
    commit('newPassword', payload)
  },
  getCustomerJobs: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/customer/' + payload + '/job'
    Vue.axios
      .get(fullUrl)
      .then((response) => {
        commit('status', '')
        commit('message', 'success')
        commit('listJobs', response.data.data.jobs)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.connection_error')
        }
      })
  },
}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
