import Vue from 'vue'

import { make } from 'vuex-pathify'
import { api } from '@/config/index'

const state = {
  status: '',
  message: '',
  listVisa: [],
  totalCount: 0,
  visaInfo: {},
  updatedVisa: {},
  error: '',
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  getVisa: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    commit('totalCount', 0)
    let fullUrl = api.baseUrl + '/visa'
    if (payload) {
      const query = new URLSearchParams(payload).toString()
      fullUrl += '?' + query
    }
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('totalCount', response.data.data.count)
        commit('listVisa', response.data.data.rows)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  getVisaById: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/visa/' + payload.id
    Vue.axios
      .get(fullUrl)
      .then(response => {
        commit('status', '')
        commit('visaInfo', response.data.data.rows[0])
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) {
            commit('error', 'error.server_error')
          }
          return
        }
        commit('status', 'error')
        commit('error', 'error.connection_error')
      })
  },
  addVisa: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/visa'
    Vue.axios
      .post(fullUrl, payload)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  updateVisa: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/visa/' + payload.id
    Vue.axios
      .put(fullUrl, payload.info)
      .then((response) => {
        commit('status', '')
        commit('message', 'success')
        commit('updatedVisa', response.data.data)
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
  deleteVisa: ({ commit }, payload) => {
    commit('status', 'loading')
    commit('message', '')
    commit('error', '')
    const fullUrl = api.baseUrl + '/visa/' + payload.id
    Vue.axios
      .delete(fullUrl)
      .then(() => {
        commit('status', '')
        commit('message', 'success')
      })
      .catch(error => {
        commit('status', 'error')
        if (error.response.status === 400) {
          commit('error', 'error.bad_input')
        }
        if (error.response.status === 500) {
          commit('error', 'error.duplicate_entity')
        }
      })
  },
}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
